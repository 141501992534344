<template>
    <i class="iconfont icon-gouwucheman" @click.stop="sellRole()"/>
    <!-- <div v-if="!$store.getters.getIsMarketTokenAuth">
         <Button :label="authTips" icon="iconfont icon-jiaose-" 
        class="mr-2" :disabled="isAuthNow" @click.stop="approveMarket()"></Button>
        
    </div>
    <div v-else-if="!$store.getters.getIsMarketNftAuth">
        <Button :label="authTipsNft" icon="iconfont icon-jiaose-" 
        class="mr-2" :disabled="isAuthNftNow" @click.stop="toNftMarket()"></Button>
    </div>
    <div v-else>
       <Button label="上架角色" icon="iconfont icon-jiaose-" 
        class="mr-2 w_10" @click.stop="sellRole()"></Button>
    </div> -->
    <Dialog header="" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
        <h5 class="mb-4 text-center">是否确认上架该角色</h5>
        <div class="mt-2 text-center">
              
              <h5 class="mb-4 text-center">{{roleInfo.role.username}}</h5>
                <div class="mt-2">
                    <span class="p-float-label ">
                        <InputText id="username" type="Number" 
                        v-model="psAmount" style="width:100%" />
                        <label for="username">售价</label>
                    </span>
                </div>
        </div>
      
        <template #footer>
            <Button label="确定" @click.stop="comfirm" icon="pi pi-check" 
            class="p-button-secondary"/>
        </template>
    </Dialog>

    
</template>

<script>
import { useStore } from "vuex";
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';
import * as LogicMarket from "../../wallet/LogicMarket";
import {market_setApprovalForAll,market_isApprovedForAll} from "../../wallet/nftAuto";
export default defineComponent({
    name:"sellModal",
    emits: ["createRoleEvent","sellRoleEvent"],
    props: [
        'roleInfo'
    ],
    setup(props,{emit}) {
        const store = useStore();
         const roleInfo=ref(props.roleInfo)
        let display=ref(props.showNameModal);
        let psAmount=ref("");
       
       let isGemDayModalShow=ref(false);
       let GemDayAmount=ref(7);
        //console.log("tests");
        watch(
            () => props.showNameModal,
            () => {
                display.value=props.showNameModal;
            }
        );
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })
        const sellRole=()=>{
             console.log("传过来的角色信息",roleInfo.value)
             display.value=true
        }
        const comfirm=()=>{
               display.value=false;
                LogicMarket.sellRole((roleInfo.value.role.itemId).toNumber(),psAmount.value).then(res=>{
                   emit("sellRoleEvent")
                })
         
            return 
        }

         //检查授权情况   
        const isMarketTokenAuth=()=>{
             LogicMarket.isAllowance().then(res=>{
                 console.log("武器组件调用检查授权",res);
                 if(res>0){
                      store.commit("UpdateIsMarketTokenAuth",true)
                     return true
                 }else{
                     store.commit("UpdateIsMarketTokenAuth",false)
                     return false;
                 }
             })
        }
       // isMarketTokenAuth()
        //授权武器合约
        const authTips=ref("当前暂未授权市场Token合约,点击授权");
        const isAuthNow=ref(false);
        const approveMarket=()=>{
            isAuthNow.value=true;
            authTips.value="正在唤起钱包授权";
            LogicMarket.toApprove().then(res=>{
                console.log("武器组件调用授权",res);
                isAuthNow.value=false;
                if(res){
                      store.commit("UpdateIsMarketTokenAuth",true)
                   // store.commit("UpdateIsMarketNftAuth",true)
                    return true
                }else{
                     store.commit("UpdateIsMarketTokenAuth",false)
                   // store.commit("UpdateIsMarketNftAuth",false)
                    return false;
                }
            })
        }

        //检查NFT授权
        // market_isApprovedForAll().then(res=>{
        //     console.log("市场合约返回授权全部调用结果",res)
        //     if(res){
        //        store.commit("UpdateIsMarketNftAuth",true)
        //     }else{
        //         store.commit("UpdateIsMarketNftAuth",false)
        //     }
        // })

        //market NFT 授权
        const authTipsNft=ref("当前暂未授权市场Nft合约,点击授权");
        const isAuthNftNow=ref(false);
        const toNftMarket=()=>{
            isAuthNftNow.value=true;
            authTipsNft.value="正在唤起钱包授权";
            market_setApprovalForAll().then(res=>{
                console.log("武器组件调用授权",res);
                isAuthNftNow.value=false;
                if(res){
                   
                    store.commit("UpdateIsMarketNftAuth",true)
                    return true
                }else{
                  
                    store.commit("UpdateIsMarketNftAuth",false)
                    return false;
                }
            })
        }

        return {
            
            sellRole,
            roleInfo,
            psAmount,
            comfirm,
            display,
            approveMarket,
            authTips,
            toNftMarket,
            isAuthNftNow,
            authTipsNft
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
