<template>
	  <i class="iconfont icon-nengliang" @click.stop="buyPsModalOpen()"/>
    <Dialog header="" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
        <h5 class="mb-4 text-center">请输入你购买的体力值数量</h5>
        <div class="mt-2">
              <span class="p-float-label ">
                <InputText id="username" type="Number" v-model="psAmount" style="width:100%" />
                <label for="username">购买数量</label>
            </span>
        </div>
      
        <template #footer>
            <Button label="确定" @click="comfirm" icon="pi pi-check" 
            class="p-button-secondary"/>
        </template>
    </Dialog>

     <Dialog header="" v-model:visible="isGemDayModalShow" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" >
        <h5 class="mb-0 text-center">请输入你购买的宝石维护天数</h5>
        <p class="text-danger mb-4 text-center"  style="font-size:xx-small">
            (至少购买七天)</p>
        <div class="mt-2">
              <span class="p-float-label ">
                <InputText type="Number" v-model="GemDayAmount" style="width:100%" />
                <label for="username">购买天数</label>
            </span>
        </div>
      
        <template #footer>
            <Button label="确定" @click="ComfirmBuyGemDay" icon="pi pi-check" 
            class="p-button-secondary" :disabled="GemDayAmount<7"/>
        </template>
    </Dialog>
</template>

<script>
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';
import * as logicBoss from "../../wallet/LogicBoss";
import { useStore } from "vuex";
export default defineComponent({
    name:"buyPsBtnModal",
    emits: ["getRoleListEvent"],
    props: [
        'roleInfo'
    ],
    setup(props,{emit}) {
         const store = useStore();
         const roleInfo=ref(props.roleInfo)
        let display=ref(props.showNameModal);
        let psAmount=ref("");
       
       let isGemDayModalShow=ref(false);
       let GemDayAmount=ref(7);
        //console.log("tests");
        watch(
            () => props.showNameModal,
            () => {
                display.value=props.showNameModal;
            }
        );
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })
        const buyPsModalOpen=()=>{
             console.log("传过来的角色信息",roleInfo.value)
             display.value=true
        }
        const comfirm=()=>{
            display.value=false;
            logicBoss.rps((roleInfo.value.role.itemId).toNumber(),psAmount.value).then(res=>{
                 store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
                emit('getRoleListEvent')
            })
         
            return 
        }

        const ComfirmBuyGemDay=()=>{
             isGemDayModalShow.value=false;
             logicBoss.gemMaintenance((roleInfo.value.role.itemId).toNumber(),GemDayAmount.value).then(res=>{
               console.log("购买天数返回",res);
           })
        }

        const buyGemDayModalOpen=()=>{
            isGemDayModalShow.value=true;
        }
        return {
            buyPsModalOpen,
            buyGemDayModalOpen,
            isGemDayModalShow,
            ComfirmBuyGemDay,
            psAmount,
            GemDayAmount,
            comfirm,
            display
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
