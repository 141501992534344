<template>
	 <i class="iconfont icon-liwu"  @click.stop="ModalOpen()"/>
    <Dialog header="" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
        <h5 class="mb-4 text-center">赠送给他人</h5>
        <div class="mt-2">
              <span class="p-float-label ">
                <InputText id="username" type="text" v-model="address"
                 style="width:100%" />
                <label for="username">请输入对方地址</label>
            </span>
        </div>
      
        <template #footer>
            <Button label="确定" @click="comfirm" icon="pi pi-check" 
            class="p-button-secondary"/>
        </template>
    </Dialog>

   
</template>

<script>
import { useStore } from "vuex";
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';
import * as LogicTransferFrom from "../../wallet/LogicTransferFrom";
import {LogicTransfer_setApprovalForAll,LogicTransfer_isApprovedForAll} from "../../wallet/nftAuto";
export default defineComponent({
    name:"buyPsBtnModal",
    emits: ["tarnferRoleEvent"],
    props: [
        'roleInfo'
    ],
    setup(props,{emit}) {
         const store = useStore();
         const roleInfo=ref(props.roleInfo)
        let display=ref(props.showNameModal);
        let address=ref("");
       
       let isGemDayModalShow=ref(false);
       let GemDayAmount=ref(7);
        //console.log("tests");
        watch(
            () => props.showNameModal,
            () => {
                display.value=props.showNameModal;
            }
        );
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })
        const ModalOpen=()=>{
             console.log("传过来的角色信息",roleInfo.value)
             display.value=true
        }
        const comfirm=()=>{
               display.value=false;
           LogicTransferFrom.roleTransferFrom(address.value,(roleInfo.value.role.itemId).toNumber()).then(res=>{
             emit('tarnferRoleEvent') 
           })
         
            return 
        }

     
        //检查NFT授权
        LogicTransfer_isApprovedForAll().then(res=>{
            console.log("市场合约返回授权全部调用结果",res)
            if(res){
               store.commit("UpdateIsLogicTransferNftAuth",true)
            }else{
                store.commit("UpdateIsLogicTransferNftAuth",false)
            }
        })

        // NFT 授权
         const authTipsNft=ref("当前暂未授权市场Nft合约,点击授权");
        const isAuthNftNow=ref(false);
        const toNftTransfer=()=>{
            isAuthNftNow.value=true;
            authTipsNft.value="正在唤起钱包授权";
            LogicTransfer_setApprovalForAll().then(res=>{
                console.log("武器组件调用授权",res);
                isAuthNftNow.value=false;
                if(res){
                   
                    store.commit("UpdateIsLogicTransferNftAuth",true)
                    return true
                }else{
                  
                    store.commit("UpdateIsLogicTransferNftAuth",false)
                    return false;
                }
            })
        }
       

        return {
            ModalOpen,
            address,
            GemDayAmount,
            comfirm,
            display,
            toNftTransfer
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
