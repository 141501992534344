<template>
	<div class="grid">
		<div class="col-12 lg:col-6 ">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center ">
					<div>
						<span class="block text-500 font-medium mb-3">
							当前持有角色数量
						</span>
						<div class="text-color-green font-medium text-xl">
							{{RoleList.length}}
						</div>
					</div>

				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
		</div>
		<div class="col-12 lg:col-6">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center ">
					<div>
						<span class="block text-500 font-medium mb-3">
							可探索角色数量
						</span>
						<div class="text-green-500 font-medium text-xl">
							{{TotalminePower}} / {{RoleList.length}}
						</div>
					</div>

				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
		</div>
		<!-- <div class="col-12 lg:col-4 xl:col-4">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center ">
					<div>
						<span class="block text-500 font-medium mb-3">
							伤害值
						</span>
						<div class="text-pink-500 font-medium text-xl">152</div>
					</div>

				</div>
				<span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span>
			</div>
		</div> -->
	</div>

	<div class="content_title mt-4 mb-4">
		创建角色
	</div>
	<div class="grid">
		<div class="col-12">
			<createRole @getRoleListEvent=getRoleListEvent />
		</div>
	</div>
	<div class="content_title mt-4 mb-4">
		我的人物
	</div>
	<div class="grid">
		<div class="col-12">
			<Button label="刷新我的角色列表" icon="iconfont icon-jiaose-" class="mr-2" @click="getRoleListEvent()"></Button>
		</div>
		<div class="col-12 lg:col-6 xl:col-4" v-for="item,index in RoleList" v-if="RoleList">
			<div class="card mb-0 text-center imgCard text-left" :class="[{
						'noClick':item.arms.length>=10 || item.maxGems.toNumber()<=item.gemstones.length, 
						'noClick':item.isShow
					}]" @click="(item.arms.length<10 || item.maxGems.toNumber()>item.gemstones.length) && openUpgrade((item.role.itemId).toNumber())">
				<Button label="立即升级" icon="iconfont icon-jiaose-" class="toUpgradeBtn" @click="openUpgrade((item.role.itemId).toNumber())"
					v-if="item.arms.length<10 || item.maxGems.toNumber()>item.gemstones.length"></Button>
				<Button label="当前已不可升级" icon="iconfont icon-jiaose-" class="toUpgradeBtn " disabled v-else></Button>

				<div class="imgBox">

					<img src="@/assets/img/monkey.jpg" v-if="(item.role.number).toNumber()==1" />
					<img src="@/assets/img/pig.jpg" v-if="(item.role.number).toNumber()==2" />
					<img src="@/assets/img/shazeng.jpg" v-if="(item.role.number).toNumber()==3" />
					<div class="msgBox flex justify-content-between  ">
						<div>
							<h4 class="mb-4">
								{{item.role.username}}
							</h4>
							<h6 class="mb-3">
								<span class="mr-2">等级：{{(item.role.level).toNumber()}}</span>
								<span>ID：{{(item.role.itemId).toNumber()}}</span>
							</h6>
							<div class="innerBox">
								<div class="bolderBox">
									<h5>战力:{{item.minePower.toNumber()}}</h5>
								</div>
								<div class="bolderBox">
									<h5>等级:{{item.role.rank}}</h5>
								</div>
								<div class="bolderBox">
									<h5>体力:{{(item.role.ps).toNumber()}}</h5>
								</div>
								<!-- <div class="bolderBox">
									<h5>天数:{{(item.role.leaseExpireTime).toNumber()}}</h5>
								</div> -->
							</div>
						</div>

						<div class="flex bottom_content">
							<h4 class="mr-4">
								<i class="iconfont icon-jian-tianchong" /> {{item.arms.length}}/10
							</h4>
							<h4>
								<i class="iconfont icon-zhubaopeishi" />
								{{item.gemstones.length}}/{{item.maxGems.toNumber()}}
							</h4>
						</div>
					</div>
					<div class="canMineBox flex justify-content-between align-items-center">
						 <div class="  innerBox">
							<i class="pi pi-fw pi-check text-success" v-if="item.canMine"></i>
							<i class="pi pi-fw pi-times text-danger" v-else></i>
							<span  class="ml-1">
								canmine
							</span>
						</div>
						<div class="  innerBox">
							<i class="pi pi-fw pi-check text-success" v-if="item.role.leaseExpireTime>0"></i>
							<i class="pi pi-fw pi-times text-danger" v-else></i>
							<span>
								合约({{item.role.leaseExpireTime}})
							</span>
						</div>
						<div class="innerBox">
							<i class="pi pi-fw pi-check text-success" v-if="item.role.ps>0"></i>
							<i class="pi pi-fw pi-times text-danger" v-else></i>
							<!-- <span class="mr-1">{{item.role.ps}}</span> -->
							<span>
								体力
							</span>
						</div>
					</div>
					
				</div>
				<div class="imgBoxShow">
					<div style="width:100%">
						<div class="iconBox mb-4">
							<i class="iconfont icon-shanchu" />
							<i class="iconfont icon-ren" @click="openUpgrade((item.role.itemId).toNumber())" v-if="item.arms.length<10 || item.maxGems.toNumber()>item.gemstones.length" />
							<buyPsBtnModal :roleInfo="item" @getRoleListEvent="getRoleListEvent" />
							<tarnsferRole :roleInfo="item" @tarnferRoleEvent="tarnferRoleEvent" />
							<sellModal :roleInfo="item" @sellRoleEvent="sellRoleEvent"/>

						</div>
						<div class="functionBtn mt-4">
							<buyBtnModal :roleInfo="item" v-if="item.gemstones.length>0"  @getRoleListEvent="getRoleListEvent" />
							<Button label="查看角色详情" class="mt-2 w_10" @click.stop="getRoleInfo(index,item)"></Button>

						</div>
					</div>
				</div>
				<RoleDetailBox :roleInfo="item" :itemIndex="index" v-if="item.isShow"
					@closeRoleDetail="closeRoleDetail" type="rolePage"/>


			</div>
		</div>

	</div>
</template>

<script>
	import {
		defineComponent,
		ref,
		store,
		computed
	} from 'vue';

	import createRole from "../components/Create/role.vue";
	import {
		getRoleList
	} from "../wallet/LogicRole";
	import buyBtnModal from "../components/Modal/buyModal.vue";
	import buyPsBtnModal from "../components/Modal/buyPsModal.vue";
	import tarnsferRole from "../components/Modal/tarnsferRole.vue";
	import sellModal from "../components/Modal/sellModal.vue";
	import RoleDetailBox from "../components/RolePage/detailBox.vue";
	import {
		useRouter
	} from "vue-router";
	import {
		getRoleArms
	} from "../wallet/LogicRole";
	export default defineComponent({
		name: "rolePage",

		components: {
			createRole,
			buyBtnModal,
			buyPsBtnModal,
			sellModal,
			RoleDetailBox,
			tarnsferRole
		},
		setup() {
			const router = useRouter()
			let RoleList = ref('');
			let TotalminePower =ref(0);
			const getRoleListEvent = () => {
				RoleList.value = [];
				getRoleList().then(res => {
					console.log("返回数组", res)
					let newRes = res.map((item, index, res) => {
						let newArr = [];
						newArr.arms = item.arms;
						newArr.canMine = item.canMine;
						newArr.gemstones = item.gemstones;
						newArr.maxGems = item.maxGems;
						newArr.minePower = item.minePower;
						newArr.maxGems = item.maxGems;
						newArr.role = item.role;
						newArr.isShow = false;
						//res.concat({"isShow":false});
						return newArr;
					});
					TotalminePower.value=0;
					let calArr=newRes.map((item)=>{
						
						if(item.minePower>0&&item.canMine&&(item.role.leaseExpireTime).toNumber()>0){
							TotalminePower.value=TotalminePower.value+1;
						}
						return false;
					})
					// let sum = calArr.reduce((pre, cur) => {
						
					// 	return Number(pre) + Number(cur);
					// },0)
					// TotalminePower.value=sum;
					console.log("当前可探索战队",calArr)
					RoleList.value = newRes;

				}).catch(error => {
					console.log("调用方法失败")
				})
			}
			getRoleListEvent();
			//获取当前角色信息
			let isShowInfo = ref(false);
			let roleInfo = ref();
			let roleInfoType = ref(0);
			const getRoleInfo = (index, roleInfo) => {

				RoleList.value[index].isShow = true;

				console.log("获取战队详情", RoleList.value[index].isShow)
				// })
			}
			//打开角色升级页面
			const openUpgrade = (id) => {
				router.push("/role/upgrade/"+id);
			}

			//关闭角色详情
			const closeRoleDetail = (itemIndex) => {
				RoleList.value[itemIndex].isShow = false;
			}

			//上架角色组件调用
			const sellRoleEvent = () => {
				
				getRoleListEvent();
			}

			//赠送角色组件调用
			const tarnferRoleEvent = () => {
				getRoleListEvent();
			}

			return {
				RoleList,
				getRoleListEvent,
				openUpgrade,
				getRoleInfo,
				roleInfoType,
				roleInfo,
				closeRoleDetail,
				tarnferRoleEvent,
				sellRoleEvent,
				TotalminePower
			}
		}

	})
</script>
<style lang="scss">
	.imgCard {
		position: relative;

		.toUpgradeBtn {
			position: absolute;
			width: 100%;
			top: 0;
		}

		.detail {
			margin-bottom: 1rem !important;

			h5 {

				flex: 1
			}

		}

		.imgBox {
			position: relative;
			h4,h5,h6{
				margin: 0;	
				font-weight: bold;
				color:#CBDDE6;
			}
			h5{
				font-size: 1rem;
			}
			h6{
				font-size: 1rem;
				
			}
			.msgBox {
				width: 100%;
				bottom: 0;
				position: absolute;
				height:100%;
				background: rgb(0,0,0,0.6);
				flex-direction:column;
				padding: 1rem;
				.innerBox{
					
					
					
					text-align: left;
					
					.bolderBox{
						background:#16202F;
						padding: 10px 1.1rem;
						border-left: 5px solid $themeColor;
						margin-bottom: 10px;
						width: 45%;
						&:nth-child(2) {
							border-left: 5px solid $themeColor;
						}
						&:last-child {
							margin-right: 0;
						}
					}
					&:last-child {
						border-right: 0px;
					}
				}
				.iconfont {
					color: $themeColor;
					font-size: 1.5rem;
				}
				.bottom_content{
					margin-bottom: 60px;
				}
			}

			.armBox {
				position: absolute;
				top: 0;
				width: 100%;
				background: #17212f;
				padding: 10px;
				text-align: right;
				justify-content: flex-end
			}
		}

		.imgBoxShow {
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.7);
			top: 0;
			display: flex;
			align-items: center;

			padding: 0 20px;
			display: none;

			.iconBox {
				height: auto;
				display: flex;
				justify-content: space-between;

				.iconfont {
					display: block;
					background: $themeColor;
					color: #16202F;
					padding: 10px;
					border-radius: $radisIconbtn;

					// margin: 0 8px;
					&:last-child {
						margin: 0;
					}

					&:first-child {
						margin: 0;
					}
				}
			}

			.functionBtn {}
		}
		.canMineBox{
			height: 60px;
			position: absolute;
			bottom: 0;
			width: 100%;
			background: #17212f;
			background: #17212f;
    		padding: 10px 0;
			.innerBox{
				flex: 1;
				margin: 5px;
				text-align: center;
				background: #1e2c40;
   				 padding: 10px 2px;
			}
		}

		&:hover {
			.imgBoxShow {
				display: flex;
			}
		}

		&.noClick {
			&:hover {
				.imgBoxShow {
					display: none;
				}
			}
		}
	}
</style>